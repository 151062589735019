

















































































































































































































































































import config from "@/config";
import {VtableHeader} from "@/store/modules/metaBlock";
import {Component, Vue, Watch} from "vue-property-decorator";
import {Getter, Action, State} from "vuex-class";
import Paginate from "vuejs-paginate";
import MyBackTag from "@/components/common/backTag.vue";
import {MetaCoinHistoryGetParams} from "@/api/api_helper";
import {getCoinInfoWithAddress} from "@/api";
import {UserInfoTypes} from "@/metaid-types";
import {mcBlockPath} from "@/store/modules/mcBlock";
import MyCountTo from "@/components/common/myCountTo.vue";
import {UserMetaBlockInfo} from "@/store/modules/metaBlockUserInfo";
import {log} from "node:console";
import {MetaIdDataAllInfoInterface} from "@/store/modules/metaIdDataView";
import {number} from "echarts";

@Component({
  name: "mcHome",
  filters: {
    protocolSplit(val: string, tag = "-") {
      return val.split(tag)[0];
    },
  },
  components: {
    Paginate,
    MyBackTag,
    MyCountTo,
  },
})
// eslint-disable-next-line @typescript-eslint/class-name-casing
export default class mcHome extends Vue {
  @Getter("mcBlock/tableHeader") tableHeader!: Array<VtableHeader>;
  @Getter("mcBlock/tableData") tableData!: Array<any>;
  @Getter("mcDetailTableMaxPages") mcDetailTableMaxPages!: number;
  @Getter("currentUser") currentUser!: UserInfoTypes;
  @Getter("metaBlockUserInfo/userMetaBlockInfo")
  userBlockInfo!: UserMetaBlockInfo;

  @Action("mcBlock/commitTableHeader") commitTableHeader!: Function;
  @Getter("metaIdDataView/metaIdAllViewData")
  viewData!: MetaIdDataAllInfoInterface;
  page = 1;
  pageSize: number = config.mcBlockTablePageMaxSize as number;
  title?: string = "";
  mcBalance?: number = 0;
  mcBlockSize: number = config.mcBlockSize as number
  readonly items = [{
    name: '体积',
    type: 0
  }, {
    name: '上链费',
    type: 1
  }];
  tabSelect = 0;

  @Watch('tableHeader')
  changetableHeader(newV: any) {
    // console.log('newV',newV)
  }

  @Watch('tabSelect')
  selectChange(newV: number) {
    console.log('newV',newV)
    this.commitTableHeader({type: newV})
  }


  @Watch("currentUser")
  watchCurrentUser(currentUser: any) {
    this.getUserBlance();
  }

  getUserBlance() {
    if (this.currentUser) {
      getCoinInfoWithAddress(this.currentUser.address as string).then(
          (res: any) => {
            console.log(res);
            res && (res.forEach((element: any) => {
              if (element.codehash === "777e4dd291059c9f7a0fd563f7204576dcceb791" && element.genesis === "54256eb1b9c815a37c4af1b82791ec6bdf5b3fa3" && element.sensibleId === "3e04f81d7fa7d4d606c3c4c8e8d3a8dcf58b5808740d40a445f3884e126bc7fd00000000") {
                this.mcBalance = element.balance + element.pendingBalance;
              }
            }));
          }
      );
    }
  }

  getTableData() {
    const params: MetaCoinHistoryGetParams = {
      metaid: this.currentUser.metaId,
      page: this.page,
      pagesize: this.pageSize,
    };
    this.$store.dispatch(mcBlockPath.getMetaCoinHistoryAction, params);
    // this.$store.dispatch(MetaBlockTablePath.getBlockTxList, {
    //   Page: this.page.toString(),
    //   PageSize: this.pageSize.toString(),
    // });
  }

  inputChange() {
    // this.getTableData();
    this.$store.commit(mcBlockPath.updateTableData, {
      page: this.page,
      pageSize: config.tablePage,
    });
  }

  mounted() {
    this.title = `$vuetify.${this.$route.meta.title}`;
    this.getTableData();
    this.getUserBlance();
    if (!this.userBlockInfo.metacoin) {
      if (this.currentUser) {
        this.$store.dispatch("metaBlockUserInfo/getMetaUserInfo", {
          metaid: this.currentUser.metaId,
        });
      }
    }
    if (!this.viewData?.metablockcount) {
      this.$store.dispatch("metaIdDataView/getAllMetIdData");
    }
  }
}
